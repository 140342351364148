import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { cookieRoute, privacyRoute, termsRoute } from 'common/routes';
import { pricingRoute } from 'payment/routes';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useMeQuery } from 'user/queries/me';
import { BugReportDialog } from './BugReportDialog';
import { Mark } from './Mark';
import {
  FacebookLogo,
  InstagramLogo,
  SpotifyLogo,
  TwitterLogo,
  YoutubeLogo
} from './SocialLogos';

export const Footer = () => {
  const { data: authUser } = useMeQuery();
  const year = useMemo(() => new Date().getFullYear().toString(), []);
  const theme = useTheme();
  const isMdDw = useMediaQuery(theme.breakpoints.down('xl'));
  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const closeModal = () => setShowModal(false);

  return (
    <Box component={'footer'} padding={9} maxWidth={1200} margin={'0 auto'}>
      <BugReportDialog open={showModal} onClose={closeModal} />
      <Grid
        container
        alignItems={'center'}
        spacing={2}
        justifyContent={'center'}
      >
        <Grid item xs={12} lg={1}>
          <Box display={'flex'} justifyContent={'center'}>
            <Mark width={120} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Typography align={isMdDw ? 'center' : undefined}>
            Maastr LLC ©{year}
          </Typography>
          <Typography align={isMdDw ? 'center' : undefined}>
            All rights reserved
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2} lg={1}>
          <Typography align={'center'}>
            <Link color={'textPrimary'} target={'_blank'} href={pricingRoute()}>
              Pricing
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <Typography align={'center'}>
            <Link color={'textPrimary'} target={'_blank'} href={privacyRoute()}>
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={1} lg={1}>
          <Typography align={'center'}>
            <Link color={'textPrimary'} target={'_blank'} href={termsRoute()}>
              Terms
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <Typography align={'center'}>
            <Link color={'textPrimary'} target={'_blank'} href={cookieRoute()}>
              Cookie Policy
            </Link>
          </Typography>
        </Grid>
        {authUser?.me && (
          <Grid item xs={6} sm={3} lg={2}>
            <Typography align={'center'}>
              <Link onClick={toggleModal} color={'textPrimary'}>
                Report a problem
              </Link>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={4} lg={4}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Link
              aria-label={'Youtube'}
              href={'https://www.youtube.com/channel/UCvRhTiybsvPdsmmJWgfWluA'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              <YoutubeLogo />
            </Link>
            <Link
              aria-label={'Instagram'}
              href={'https://www.instagram.com/maastr.io'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              <InstagramLogo />
            </Link>
            <Link
              aria-label={'Twitter'}
              href={'https://twitter.com/getMaastrd'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              <TwitterLogo />
            </Link>
            <Link
              aria-label={'Facebook'}
              href={'https://www.facebook.com/GetMaastrd'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              <FacebookLogo />
            </Link>
            <Link
              aria-label={'Spotify'}
              href={'https://'}
              rel={'noreferrer'}
              target={'_blank'}
            >
              <SpotifyLogo />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
