import React, { FC } from 'react';
import { CssBaseline, Alert, Snackbar } from '@mui/material';
import Head from 'next/head';
import { useSnackbarsStore } from 'common/state/snackbars';
import { theme } from './ThemeProvider';
import { MotionBox } from 'common/components/MotionBox';

import getConfig from 'next/config';
import { Footer } from './Footer';
import { useEffect } from 'react';

const { publicRuntimeConfig } = getConfig();

const variants = {
  initial: {},
  enter: {},
  exit: {}
};

export const BaseLayout: FC = ({ children }) => {
  const { snackbars, removeSnackbar } = useSnackbarsStore();

  useEffect(() => {
    document?.getElementById('__next')?.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head>
        <meta name='theme-color' content={theme.palette.primary.main} />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/.well-known/site.webmanifest' />
        <link
          rel='mask-icon'
          href='/safari-pinned-tab.svg'
          color={theme.palette.primary.main}
        />
        <meta
          name='msapplication-TileColor'
          content={theme.palette.primary.main}
        />
        {!publicRuntimeConfig.isProductionSite && (
          <meta name='robots' content='noindex' />
        )}
      </Head>
      <CssBaseline />
      <MotionBox
        minHeight={'100vh'}
        variants={variants}
        initial='initial'
        animate='enter'
        exit='exit'
      >
        {children}
      </MotionBox>
      {snackbars?.map(({ id, duration, text, ...props }) => (
        <Snackbar
          open
          key={id}
          autoHideDuration={duration || undefined}
          onClose={() => removeSnackbar(id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => removeSnackbar(id)} {...props}>
            {text}
          </Alert>
        </Snackbar>
      ))}
      <Footer />
    </>
  );
};
